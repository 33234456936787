import { AjaxGeoSearchService } from '../../../../geo/AjaxGeoSearchService'
import { LocationSuggestionCustom } from '../domain/LocationSuggestions'

export default class FetchLocationGeoSuggestions
{
  async fetch(
    input: string,
    langCode: string,
  ): Promise<LocationSuggestionCustom[]> {
    return await (new AjaxGeoSearchService()).get(input, langCode)
  }
}
