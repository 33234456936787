
import { usePinia, useSearchStore, useSearchWizardStore } from '#imports'
import { isEqual } from 'lodash'
import { CampsiteOpeningDateRange } from '~/apps/campsite-details/types'
import { CampsiteCategoriesUpdaterJson } from '~/apps/categories/presenter-json/CampsiteCategoriesUpdaterJson'
import { DatepickerAvailabilityBaseComponent } from '~/apps/datepicker-availability/DatepickerAvailabilityBaseComponent'
import { DatepickerAvailabilityResultsDay } from '~/apps/datepicker-availability/types'
import { GeoCircle } from '~/utility/geo/GeoLocation'
import { Component, Prop, Watch } from '~/utility/pu-class-decorator'
import { CampsiteCategory } from '~/utility/types/CampsiteCategory'
import { getUrlPrefix } from '../../../../lang/locales'
import { logger } from '../../../../utility/logger'
import { OpeningDatesRange } from '../../../search-wizard/types'
import { isSearchUrlRoute } from '../../../search/isSearchUrlComponent'
import { SearchParty } from '../../../search/schemas'
import { ArriveDepartIso } from '../../../search/types'
import { SearchLinkGenerator } from '../../../url/generators/SearchLinkGenerator'
import { LocationWizardData } from '../../domain/LocationWizardData'
import { getSuggestionsMachine } from '../../inversify.config'
import DatesWizard from '../../wizards/dates/DatesWizard.vue'
import { SearchWizardFilters } from '../domain/SearchWizardFilters'
import { SuggestionsMachineInterpret } from '../location/machine/suggestionsMachine'
import { CampsitesSearchWizardMainProps } from './CampsitesSearchWizardMainProps'

const isDev = process.env.NODE_ENV !== 'production'

@Component<SearchBarDesktop>({
  components: {
    DatesWizard,
  },
  fetchKey: 'search-bar-desktop',
  fetchOnServer: false,
  fetch: function () {
    return this.fetch()
  },
  setup(context) {
    if (isDev) console.log('SearchBarDesktop.setup', context)
    const searchStore = useSearchStore(usePinia())
    const searchWizardStore = useSearchWizardStore(usePinia())
    return { searchStore, searchWizardStore }
  },
})
export default class SearchBarDesktop
  extends DatepickerAvailabilityBaseComponent
  implements CampsitesSearchWizardMainProps
{
  searchStore: ReturnType<typeof useSearchStore>
  searchWizardStore: ReturnType<typeof useSearchWizardStore>

  async fetch() {
    const lang = this.$route?.params?.lang || 'en-gb'
    if (this.$route?.name === 'campsite-detail') {
      return await this.initAvailability(lang)
    }
  }

  @Prop({ default: false })
    showOpenCategories: boolean

  @Prop({
    default: false,
    type: Boolean,
  })
    noLocation: boolean

  @Prop()
    campsiteHierarchyPath?: string

  @Prop()
    campsiteId?: string

  @Prop()
    campsiteOpeningDateRanges?: CampsiteOpeningDateRange[]

  @Prop()
    campsitePitchCategoryIds?: number[]

  @Prop()
    campsiteCategories?: CampsiteCategory[]

  @Prop()
    currentPitchtypeId?: string

  @Prop({ required: false, type: Boolean, default: false })
    isSearchResults: boolean

  suggestionsMachine: SuggestionsMachineInterpret | null = null
  suggestionsState: SuggestionsMachineInterpret['state'] | null = null
  inputIsFocused = false

  get context() {
    return this.searchWizardStore.context
  }

  get isClearSearch() {
    return this.searchWizardStore.isClearSearch
  }

  get suggestionsInput() {
    return this.suggestionsState?.context.input || ''
  }

  get suggestions() {
    return this.suggestionsState?.context.suggestions || {}
  }

  highlightErrors = false

  mounted() {
    this.suggestionsMachine = getSuggestionsMachine(
      this.$route?.params?.lang || 'en-gb',
    )
    this.suggestionsMachine.onTransition((state) => {
      this.suggestionsState = state
    })
    this.suggestionsMachine.send({
      type: 'SET_INITIAL_INPUT',
      input: this.searchInputValue,
    })
    if (this.showOpenCategories) {
      this.openCategories()
    }
  }

  updateSuggestions(input: string) {
    this.suggestionsMachine?.send({ type: 'SET_INPUT', input })
  }

  selectLocation(name: string) {
    this.suggestionsMachine?.send({ type: 'SELECT_LOCATION', name })
  }

  submit() {
    this.highlightErrors = true
  }

  clearInput() {
    logger('SearchBarDesktop.clearInput')
    this.searchWizardStore.send({ type: 'CLEAR_INPUT' })
  }

  get inputPlaceholder() {
    return this.$t(
      this.inputIsFocused
        ? 'Where are you going?'
        : this.searchWizardStore.inputPlaceholder,
    ).toString()
  }

  get searchInputValue() {
    return (
      (this.context?.searchInputValue?.type === 'CUSTOM' &&
        this.context?.searchInputValue?.text) ||
      ''
    )
  }

  set searchInputValue(value: string) {
    // this.searchWizardStore.send({ type: 'SET_INPUT_VALUE', value })
  }

  get searchFilters(): SearchWizardFilters {
    return this.searchWizardStore.wizardFilters
  }

  categories = new CampsiteCategoriesUpdaterJson(
    this.$route?.params?.lang || 'en-gb',
  ).getPresenter().byIdMap

  get selectedCategories() {
    return this.categoryIds.map((id) => this.categories[id])
  }

  get campsiteDatepickerAvailability():
  | DatepickerAvailabilityResultsDay[]
  | undefined {
    if (this.campsiteId) {
      return this.datepickerAvailability
    }
    return undefined
  }

  get openingDates(): OpeningDatesRange[] {
    if (!this.campsiteId) {
      return []
    }
    const openingDateRanges = this.campsiteOpeningDateRanges
    return openingDateRanges ? this.mapOpeningDateRanges(openingDateRanges) : []
  }

  get allowedCategoryIds(): string[] | undefined {
    if (!this.campsiteId) {
      return undefined
    }
    if (this.campsitePitchCategoryIds) {
      return this.campsitePitchCategoryIds.map((cat) => `${cat}`)
    }
    return this.campsiteCategories?.map((cat) => `${cat.id}`)
  }

  private mapOpeningDateRanges(openingDateRanges) {
    return openingDateRanges.map((r) => {
      return {
        from: r.openingDate,
        to: r.closingDate,
      }
    })
  }

  changeLocation({
    location,
    term,
  }: {
    location: LocationWizardData
    term?: string
  }) {
    this.searchWizardStore.send('SET_LOCATION', {
      data: location,
      inputValue: term,
    })
    this.cancelEdit()
  }

  openCategories() {
    this.searchWizardStore.send('EDIT_CATEGORIES')
  }

  get categoryIds(): string[] {
    return this.searchWizardStore.wizardFilters.categoryIds || []
  }

  set categoryIds(value: string[]) {
    this.searchWizardStore.send({
      type: 'SET_CATEGORIES',
      categoryIds: value,
    })
  }

  openLocation() {
    this.searchWizardStore.send({ type: 'FOCUS_LOCATION' })
  }

  get locationInputFocused(): boolean {
    return this.searchWizardStore.states.includes('locationFocus')
  }

  openDates() {
    this.searchWizardStore.send('EDIT_DATES')
    this.scrollToInput()
  }

  private hasScrolledInput = false

  scrollToInput() {
    if (!this.hasScrolledInput) {
      const scrollTarget = document.querySelector(
        '[data-desktop-location-scroll-target]',
      ) as HTMLElement
      if (scrollTarget) {
        this.hasScrolledInput = true
        this.$puScrollTo('[data-desktop-location-scroll-target]', {
          duration: 300,
          offset: -18,
        })
      }
    }
  }

  get dates(): ArriveDepartIso | undefined {
    return this.searchWizardStore.wizardFilters.dates
  }

  set dates(dates: ArriveDepartIso | undefined) {
    this.searchWizardStore.send({
      type: 'SET_DATES',
      dateRange: dates || null,
    })
  }

  get hasBounds() {
    return !!this.searchWizardStore.wizardFilters.bounds
  }

  openGuests() {
    this.searchWizardStore.send('EDIT_GUESTS')
  }

  get guests(): SearchParty {
    return (
      this.searchWizardStore.wizardFilters.party || {
        adults: 2,
        childAges: [],
      }
    )
  }

  set guests(guests: SearchParty) {
    this.searchWizardStore.send({ type: 'SET_GUESTS', guests })
  }

  get guestsEnabled() {
    return this.$isDesktop || !!(this.dates?.arrive && this.dates?.depart)
  }

  get area(): GeoCircle | undefined {
    return this.searchWizardStore.wizardFilters.area
  }

  get searchEnabled() {
    return (
      this.searchWizardStore.isSearchEnabled ||
      this.$route.name === 'index' ||
      this.$route.name?.includes('searchedit')
    )
  }

  clearDates() {
    this.searchWizardStore.send({
      type: 'CLEAR_DATES',
    })
  }

  modifyWithin(within: number) {
    this.searchWizardStore.send('SET_WITHIN', { within })
  }

  cancelEdit() {
    this.searchWizardStore.send('CANCEL_EDIT')
  }

  clear() {
    this.searchWizardStore.send({ type: 'CLEAR_SEARCH_PARAMS' })
    this.suggestionsMachine?.send({ type: 'SET_INPUT', input: '' })
  }

  get selectedLocation() {
    return this.searchWizardStore.context.selectedLocation
  }

  get selectedLocationType() {
    return this.selectedLocation?.dataType || null
  }

  get searchStepOpen() {
    return !this.searchWizardStore.states.includes('idle')
  }

  get isEditSearch() {
    return (
      this.guests ||
      this.dates ||
      this.categoryIds.length ||
      this.searchInputValue
    )
  }

  @Watch('searchURL', { deep: true })
  async onSearchURLChange(newVal, oldVal) {
    if (isEqual(newVal, oldVal)) return
    if (this.shouldDoLiveSearch()) {
      await this.formSubmit()
    }
  }

  @Watch('formActionUrl')
  async onFormActionUrlChange(newVal, oldVal) {
    if (newVal === oldVal) return
    if (this.shouldDoLiveSearch()) {
      await this.formSubmit()
    }
  }

  shouldDoLiveSearch() {
    if (!isSearchUrlRoute(this.$route) && this.$route.name !== 'campsite-detail') return false
    return (
      this.$route.name !== 'index' &&
      this.$route.name !== 'searchedit' &&
      !this.searchWizardStore.panelOpen
    )
  }

  async formSubmit(event?) {
    if (event) event.preventDefault()
    if (!this.searchEnabled) {
      return
    }
    if (this.campsiteSlug && !this.searchURL.query.arrive) {
      return
    }
    const path = this.formActionUrl
    logger('SearchBarDesktop.formSubmit', path)
    await this.$router.push({
      path,
      query: this.searchURL.query as Record<
      string,
      string | string[] | undefined
      >,
    })
  }

  get formActionUrl() {
    const urlPrefix = getUrlPrefix(this.$route.params.lang)
    if (this.campsiteSlug) {
      return `${urlPrefix}/campsites/${this.campsiteHierarchyPath}${this.campsiteSlug}/`
    }
    if (this.selectedLocation?.dataType === 'campsite') {
      return `${urlPrefix}/campsites/${this.selectedLocation?.hierarchyPath}${this.selectedLocation?.slug}/`
    }
    if (this.area) {
      return `${urlPrefix}/search/`
    } else {
      // TODO: check for facets url
      return `${urlPrefix}${this.searchURL.path}`
    }
  }

  get searchURL() {
    const urlGenerator = new SearchLinkGenerator()
    const urlData = urlGenerator.getUrlData({
      category: 'search',
      excludeType: 'searchWizard',
      filters: this.searchFilters,
    })
    return urlData
  }

  get isSearchSubmitting() {
    return ['searching', 'priceSearching'].some((state) =>
      this.searchStore.states.includes(state),
    )
  }
}
